<template>
  <div class="content-wrapper">
    <div v-if="course" class="menu-title">Category: {{ course }}</div>
    <div v-if="year" class="menu-title">Archives: {{ year }}</div>
    <div v-for="item in getMenuItems" :key="item" class="content">
      <div>
        <a :href="item.link" target="_blank">
          <BlogImg :link="item.img"/>
        </a>
        <div class="content-info" >
          <BlogName :bname="item.bname"/>
          <CreatorName :crname="item.crname"/>
          <CourseName :coname="item.coname"/>
          <CreatedAt :created="item.created"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogImg from './blog/BlogImg.vue'
import BlogName from './blog/BlogName.vue'
import CreatorName from './blog/CreatorName.vue'
import CourseName from './blog/CourseName.vue'
import CreatedAt from './blog/CreatedAt.vue'

export default {
  name: 'Content',
  components: {
    BlogImg,
    BlogName,
    CreatorName,
    CourseName,
    CreatedAt
  },
  props: [ 'course', 'year' ],
  methods: {
    getYear : function (val) {
      return val.split('/')[0]
    }
  },
  data () {
    return {
      items : [
        { link: 'https://graduation-work.net/archives/202411matsushita', img: '202411matsushita.jpg', bname: '山下産業', crname: '松下', coname: 'WEBデザイナー', created: '2024/11'},
        { link: 'https://graduation-work.net/archives/202411nishioka', img: '202411nishioka.jpg', bname: '菊水堂', crname: '西岡', coname: 'WEBデザイナー', created: '2024/11'},
        { link: 'https://graduation-work.net/archives/202409takada', img: '202409takada.jpg', bname: 'TOTAL SALON SPROUT', crname: '高田', coname: 'WEBデザイナー', created: '2024/09'},
        { link: 'https://graduation-work.net/archives/202409hurusyou', img: '202409hurusyou.jpg', bname: 'こども創造学舎', crname: '古庄', coname: 'WEBデザイナー', created: '2024/09'},
        { link: 'https://graduation-work.net/archives/202408yamakawa', img: '202408yamakawa.jpg', bname: 'wayo和裁スクール', crname: '山川', coname: 'WEBデザイナー', created: '2024/08'},
        { link: 'https://graduation-work.net/archives/202407matsushima', img: '202407matsushima.jpg', bname: 'Chèrim nail salon', crname: '松嶋', coname: 'WEBデザイナー', created: '2024/07'},
        { link: 'https://graduation-work.net/archives/202406kuroki', img: '202406kuroki.jpg', bname: 'テリーヌショコラ専門店MITSUNAGA', crname: '黒木', coname: 'WEBデザイナー', created: '2024/06'},
        { link: 'https://graduation-work.net/archives/202406kojima', img: '202406kojima.jpg', bname: '小島金物店', crname: '小島', coname: 'WEBデザイナー', created: '2024/06'},
        { link: 'https://graduation-work.net/archives/202405takano', img: '202405takano.jpg', bname: 'DoIKebaB ドイケバブ', crname: '高野', coname: 'WEBデザイナー', created: '2024/05'},
        { link: 'https://graduation-work.net/archives/202404manabe', img: '202404manabe.jpg', bname: '四季彩 かぐや', crname: '眞鍋', coname: 'WEBデザイナー', created: '2024/04'},
        { link: 'https://graduation-work.net/archives/202402torigoe', img: '202402torigoe.jpg', bname: '鳥越養鰻', crname: '鳥越', coname: 'WEBデザイナー', created: '2024/02'},
        { link: 'https://graduation-work.net/archives/202402harada', img: '202402harada.jpg', bname: 'Katete Amakusa', crname: '原田', coname: 'WEBデザイナー', created: '2024/02'},
        { link: 'https://graduation-work.net/archives/202310iwata', img: '202310iwata.jpg', bname: 'くまジェラ', crname: '岩田', coname: 'WEBデザイナー', created: '2023/10'},
        { link: 'https://graduation-work.net/archives/202307nunoi', img: '202307nunoi.jpg', bname: '焼き菓子屋 clef-暮-', crname: '布井', coname: 'WEBデザイナー', created: '2023/07'},
        { link: 'https://graduation-work.net/archives/202303matsubara', img: '202303matsubara.jpg', bname: 'ポートフォリオ', crname: '松原', coname: 'WEBデザイナー', created: '2023/03'},
        { link: 'https://graduation-work.net/archives/202303satou', img: '202303satou.jpg', bname: '天草LP', crname: '佐藤', coname: 'WEBデザイナー', created: '2023/03'},
        { link: 'https://graduation-work.net/archives/202210usuki/Portfolio_usuki.pdf', img: '202210usuki.jpg', bname: 'ポートフォリオ', crname: '臼杵', coname: 'CGクリエイター', created: '2022/10' },
        { link: 'https://graduation-work.net/archives/202210tsunoda/Portfolio_tsunoda.pdf', img: '202210tsunoda.jpg', bname: 'ポートフォリオ', crname: '角田', coname: 'CGクリエイター', created: '2022/10'},
        { link: 'https://graduation-work.net/archives/202210otsuka', img: '202210otsuka.jpg', bname: 'bird cafe イロドリ', crname: '大塚', coname: 'WEBデザイナー', created: '2022/10'},
        { link: 'https://graduation-work.net/archives/202205tsunoda', img: '202205tsunoda.jpg', bname: '水前寺耳鼻咽喉科クリニック', crname: '角田', coname: 'WEBデザイナー', created: '2022/05'},
        { link: 'https://graduation-work.net/archives/202202takizaki/Portfolio_takizaki.pdf', img: '202202takizaki.jpg', bname: 'ポートフォリオ', crname: '瀧崎', coname: 'CGクリエイター', created: '2022/02'},
        { link: 'https://graduation-work.net/archives/202201shiraishi', img: '202201shiraishi.jpg', bname: 'Two Peace フライドチキンパウダー', crname: '白石', coname: 'WEBデザイナー', created: '2022/01'},
        { link: 'https://graduation-work.net/archives/202201yonemura', img: '202201yonemura.jpg', bname: 'CatWalk', crname: '米村', coname: 'WEBデザイナー', created: '2022/01'},
        { link: 'https://kumadeji.jp', img: '202111yamamoto.jpg', bname: 'くまデジ', crname: '山本', coname: 'WEBデザイナー', created: '2021/11'},
        { link: 'https://graduation-work.net/archives/202111nishino/headermovie_re.mp4', img: '202111nishino-2.jpg', bname: '熊本リビング新聞社ヘッダー動画', crname: '西野', coname: '動画クリエイター', created: '2021/11'},
        { link: 'https://graduation-work.net/archives/202111nishino', img: '202111nishino.jpg', bname: '熊本リビング新聞社', crname: '西野', coname: 'WEBデザイナー', created: '2021/11'},
        { link: 'https://graduation-work.net/archives/202110nakano', img: '202110nakano.jpg', bname: 'BURROW', crname: '中野', coname: 'WEBデザイナー', created: '2021/10'},
        { link: 'https://miryoku-labo.net/', img: '202110koga.jpg', bname: 'みりょくラボ', crname: '古閑', coname: 'WEBデザイナー', created: '2021/10'},
        { link: 'https://graduation-work.net/archives/202108yoshizato', img: '202108yoshizato.jpg', bname: "T's Barista", crname: '吉里', coname: 'WEBデザイナー', created: '2021/08'},
        { link: 'https://graduation-work.net/archives/202107uehara/monky.MP4', img: '202107uehara.jpg', bname: 'monky.mp4', crname: '上原', coname: '動画クリエイター', created: '2021/07'},
        { link: 'https://graduation-work.net/archives/202106fujiki/para-HTML', img: '202106fujiki.jpg', bname: 'PARAFUSE', crname: '藤木', coname: 'WEBデザイナー', created: '2021/06'},
        { link: 'https://graduation-work.net/archives/202106okada/', img: '202106okada.jpg', bname: 'BukaRapi', crname: '岡田', coname: 'WEBデザイナー', created: '2021/06'},
        { link: 'https://graduation-work.net/archives/202105shigeoka/', img: '202105shigeoka.jpg', bname: '熊本博愛病院', crname: '重岡', coname: 'WEBデザイナー', created: '2021/05'},
        { link: 'http://www.from-index.com/', img: '202104maeda.jpg', bname: '株式会社インデックス・アド', crname: '前田', coname: 'WEBデザイナー', created: '2021/04'},
        { link: 'https://graduation-work.net/archives/202103maeda/', img: '202103maeda.jpg', bname: '焼き鳥 玄家', crname: '前田', coname: 'WEBデザイナー', created: '2021/03'},
        { link: 'https://graduation-work.net/archives/202103yonehara/202103yonehara.mp4', img: '202103yonehara.jpg', bname: 'curry&cafe INDIGO', crname: '米原', coname: '動画クリエイター', created: '2021/03'},
        { link: 'https://graduation-work.net/archives/202011umezaki/portfolio.mp4', img: '202011umezaki.jpg', bname: '大栄通信施設株式会社PR動画', crname: '梅崎', coname: '動画クリエイター', created: '2020/11'}
      ]
    }
  },
  computed: {
    getMenuItems() {
      return this.items.filter(e => {
        return this.course === '' || e.coname === this.course || this.getYear(e.created) === this.year
      })
    }
  }
}
</script>

<style scoped>
.content-wrapper {
  width: 90%;
  margin: 0 auto 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.menu-title {
  display: block;
  width: 90%;
  margin: 30px 2%;
  color: #757575;
  font-size: .7rem;
}
.content {
  width: 29.25%;
  margin: 10px 2%;
}
.content-info {
  font-size: 0.7rem;
  margin: 10px 0 20px;
  color: #848484;
}

@media screen and (max-width: 768px) {
  .content {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 100%;
  }
}
</style>